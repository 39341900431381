import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import CreateEditor from '../editor/config';
import { useDispatch } from 'react-redux';

import { Button, ButtonWrap } from '../../../library';

import { updateDocument, notify } from '../../../../actions';

// Templates
import LegalTemplate from '../editor/temp_fin_legal.json';
import NaturalTemplate from '../editor/temp_fin_natural.json';

export const DocumentFinInfo = ({ document, template }) => {
    const history = useHistory();
    const inputNode = useRef(null);
    const editorNode = useRef(null);
    const dispatch = useDispatch();

    const [ editorValue, setEditorValue ] = useState({});
    const [ finData, setFinData ] = useState({
        sum_number: "",
        sum_text: "",
        scholarship_fin_string: "Загальна сума Договору становить __ грн. __ коп. (__ гривень ___ копійок), у тому числі податок на доходи фізичних осіб 18%  у сумі __ грн. ___ коп. (__ гривень ___ копійок) та військовий збір 5 % у сумі  ________ грн. ___ коп. (__ гривень __ копійок).",
        scholarship_fin_total: "Сума стипендії до виплати становить __ грн. __ коп. (___).",
    });
    const [ editorInited, setEditorInited ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");


    useEffect( () => {
        if( !editorInited ){

            let data = document.fin_info_data;

            if( !document.fin_info_data ){
                if( document.bank_details_template === "legal_entity"){
                    data = LegalTemplate;
                }
                if( document.bank_details_template === "natural_person"){
                    data = NaturalTemplate;
                }
            };

            CreateEditor( editorNode.current, data, setEditorValue )

            if( document.fin_fields ){
                setFinData( document.fin_fields );
            }
            setEditorInited( true );
        }
    }, [ editorInited ])

    const handleInputs = (e) => {
        const { name, value } = e.target;
        setFinData({
            ...finData,
            [name]: value
        });
    }


    const updateDocumentData = (e) => {
        updateDocument( 
            document._id, 
            { 
                status: 2,
                fin_info_data: typeof(editorValue) === "string" ? JSON.parse(editorValue) : editorValue,
                fin_fields: finData,
            }, 
            history 
        )
            .then( res => {
                console.log('res', res );;
                dispatch( notify( res.data.data.message, 'success') );
            });
    }

    return(
        <div className="documents__page"> 
            <h2> Додаток #5 </h2>
            <textarea hidden style={{ width: "100%", fontSize: "15px"}} ref={inputNode} rows="5" value={JSON.stringify(editorValue)} onChange={ e => {} } />
            <div className="documents__wrapper">

                <div className="documents__editable fin">
                    <div className="documents__finFields">
                        <label>
                            <div> 
                                Сума числом 
                            </div>
                            <input 
                                className="documents__input"
                                name="sum_number"
                                placeholder='1000 грн. 11 коп.'
                                onChange={handleInputs}
                                value={finData.sum_number}
                            />
                        </label>
                        <label>
                            <div> Сума прописом </div>
                            <input 
                                className="documents__input"
                                name="sum_text"
                                placeholder='Одна тисяча грн. одинадцять коп.'
                                onChange={handleInputs}
                                value={finData.sum_text}
                            />
                        </label>
                    </div>

                    {
                        document.bank_details_template === "natural_person" && (
                            <div>
                                <label>
                                    <div> Сума стипендії та порядок розрахунків. Умови </div>
                                    <textarea
                                        rows="5" 
                                        className="documents__input"
                                        name="scholarship_fin_string"
                                        placeholder='Одна тисяча грн. одинадцять коп.'
                                        onChange={handleInputs}
                                        value={finData.scholarship_fin_string}
                                    />
                                </label>
                                <label>
                                    <div> Сума стипендії та порядок розрахунків. Сума Стипендії  </div>
                                    <textarea
                                        rows="5" 
                                        className="documents__input"
                                        name="scholarship_fin_total"
                                        placeholder='Одна тисяча грн. одинадцять коп.'
                                        onChange={handleInputs}
                                        value={finData.scholarship_fin_total}
                                    />
                                </label>
                            </div>
                        )
                    }

                    <div className="documents__editor" ref={editorNode}></div>
                </div>
                <div className="documents__vairables fin">
                    <ButtonWrap>
                        <Button 
                            handler={ updateDocumentData } 
                            size="large"  
                            text="Збереги інформацію" 
                        />
                    </ButtonWrap>
                </div>
            </div>        
        </div>
    )


};